'use client';
import { usePathname } from 'next/navigation';
import { useSetAtom } from 'jotai';

import { useModal } from '@modal/Hooks/UseModal';
import { nlsToggleAtom } from '@natural-language-search/Atoms/NaturalLanguageSearchToggle.atom';
import { PrefetchLink } from '@shared-components/PrefetchLink/PrefetchLink';
import { ctaClickedToEvent } from '@tracking/Schemas/CtaClicked/CtaClickedToEvent';
import { trackCustomEvent } from '@tracking/Utils/TrackCustomEvent';

import { navTrayOpenAtom } from '../../Atoms/NavTrayOpenAtom';
import { navHoveredAtom } from '../../Atoms/NavHoveredAtom';
import { headerClickTracking } from '../../Constants/Header.constants';
import { footerClickTracking } from '../../Constants/Footer.constants';
import {
  FooterTrackingObj,
  HeaderTrackingObj,
  TrackedLinkProps,
} from '../../Entities/TrackedLink.entity';

export const trackEvent = (track?: {
  fn: ((trackingObj: HeaderTrackingObj | FooterTrackingObj) => void) | undefined;
  obj: Partial<HeaderTrackingObj | FooterTrackingObj>;
  origin?: 'header' | 'footer';
}) => {
  if (track && typeof track?.fn === 'function') {
    track.fn({
      ...(track.origin === 'footer' ? footerClickTracking : headerClickTracking),
      ...track.obj,
    } as HeaderTrackingObj | FooterTrackingObj);
  }
};

export const itemOnClick = (
  track?: {
    fn: ((trackingObj: HeaderTrackingObj | FooterTrackingObj) => void) | undefined;
    obj: Partial<HeaderTrackingObj | FooterTrackingObj>;
    origin?: 'header' | 'footer';
  },
  onClick?: () => void,
) => {
  trackEvent(track);
  if (typeof onClick === 'function') onClick();
};

export const trackingFunction = ({
  category,
  label,
  href,
}: HeaderTrackingObj | FooterTrackingObj) => {
  trackCustomEvent({
    event: ctaClickedToEvent({ ctaType: category, ctaWording: label, targetUrl: href || '' }),
    context: [],
  });
};

export const TrackedLink = ({ trackingObj, children, ...rest }: TrackedLinkProps) => {
  const pathname = usePathname();
  const { closeModal } = useModal();

  const setIsNavTrayOpen = useSetAtom(navTrayOpenAtom);
  const setToggleSearchBar = useSetAtom(nlsToggleAtom);
  const setIsNavHovered = useSetAtom(navHoveredAtom(rest?.navItemId));

  const handleClick = () => {
    setIsNavTrayOpen(false);
    setToggleSearchBar(false);
    setIsNavHovered(false);
    if (rest?.isNavMenuList) {
      closeModal();
    }

    if (pathname !== rest.href) {
      itemOnClick({
        fn: trackingFunction,
        obj: trackingObj,
        origin: rest?.origin,
      });
    }
  };

  return (
    <PrefetchLink
      href={rest?.href}
      key={rest?.key}
      className={rest?.className}
      onClick={handleClick}
      prefetch={rest?.prefetch}
      aria-label={rest?.ariaLabel}
    >
      {children}
    </PrefetchLink>
  );
};
