'use client';
import { FC, ReactNode, useEffect, useState } from 'react';
import { usePathname } from 'next/navigation';
import { Locale } from '@core/Entities/Locale/Locale.entity';
import { PrimaryHeaderNavSkeleton } from '../PrimaryHeaderNav/PrimaryHeaderNavSkeleton';

interface HeaderClientProps {
  defaultHeader: ReactNode;
  heywattHeader: ReactNode;
  InitialHeader: ReactNode;
  locale: Locale;
}

export const PrimaryHeaderClientSync: FC<HeaderClientProps> = ({
  defaultHeader,
  heywattHeader,
  InitialHeader = PrimaryHeaderNavSkeleton,
  locale,
}) => {
  const pathname = usePathname();
  const [currentHeader, setCurrentHeader] = useState<ReactNode>(InitialHeader);

  useEffect(() => {
    const fetchPageTheme = async () => {
      if (!pathname) return;

      let isHeywatt = pathname.includes(`/voiture-electrique`);
      isHeywatt = isHeywatt || pathname.includes(`autos/fueltype/electrique`);
      setCurrentHeader(isHeywatt ? heywattHeader : defaultHeader);
    };

    fetchPageTheme();
  }, [pathname, defaultHeader, heywattHeader, locale]);

  return <>{currentHeader}</>;
};
